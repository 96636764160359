import React from 'react';

import Icon from 'components/icons/Icon';

const LinkIcon = props => {
  const { fillColor = '#0F1111', ...otherProps } = props;
  return (
    <Icon
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}>
      <path d="M11.5 7.00049L14.8173 3.6266C16.5638 1.85032 19.4234 1.83823 21.1849 3.59969V3.59969C22.9359 5.35068 22.9359 8.18959 21.1849 9.94058L17.1799 13.9456C15.6592 15.4663 13.2081 15.5124 11.6313 14.0498L10.5 13.0005" stroke={fillColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.8553 17.812L9.53801 21.1859C7.7915 22.9622 4.93191 22.9743 3.17045 21.2128V21.2128C1.41946 19.4618 1.41946 16.6229 3.17045 14.8719L7.17542 10.8669C8.69617 9.3462 11.1472 9.30014 12.724 10.7627L13.8553 11.812" stroke={fillColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </Icon>
  );
};

export default LinkIcon;
