import React from 'react';

import Icon from 'components/icons/Icon';

const LinkIconV2 = props => {
  const { fillcolor = '#0F1111' } = props;
  return (
    <Icon
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <rect x="12.8334" y="11.6667" width="14" height="15.1667" rx="3" stroke={fillcolor} strokeWidth="2"/>
      <path d="M15.1666 5.50002V4.16669C15.1666 2.50983 13.8235 1.16669 12.1666 1.16669H4.16663C2.50977 1.16669 1.16663 2.50983 1.16663 4.16669V13.3334C1.16663 14.9902 2.50977 16.3334 4.16663 16.3334H6.99996" stroke={fillcolor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </Icon>
  );
};

export default LinkIconV2;
