import React from 'react';
import Icon from 'components/icons/Icon';

const Truck = (props) => (
  <Icon viewBox="0 0 200 200" {...props}>
    <title>Truck</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <circle fill="#ebebeb" cx="100" cy="100" r="100"></circle>
        <path d="M143.3125,79.75 L127,79.75 L127,58 L50.875,58 C44.89375,58 40,62.89375 40,68.875 L40,128.6875 L50.875,128.6875 C50.875,137.71375 58.16125,145 67.1875,145 C76.21375,145 83.5,137.71375 83.5,128.6875 L116.125,128.6875 C116.125,137.71375 123.41125,145 132.4375,145 C141.46375,145 148.75,137.71375 148.75,128.6875 L159.625,128.6875 L159.625,101.5 L143.3125,79.75 Z M67.1875,136.84375 C62.674375,136.84375 59.03125,133.200625 59.03125,128.6875 C59.03125,124.174375 62.674375,120.53125 67.1875,120.53125 C71.700625,120.53125 75.34375,124.174375 75.34375,128.6875 C75.34375,133.200625 71.700625,136.84375 67.1875,136.84375 Z M140.59375,87.90625 L151.25125,101.5 L127,101.5 L127,87.90625 L140.59375,87.90625 Z M132.4375,136.84375 C127.924375,136.84375 124.28125,133.200625 124.28125,128.6875 C124.28125,124.174375 127.924375,120.53125 132.4375,120.53125 C136.950625,120.53125 140.59375,124.174375 140.59375,128.6875 C140.59375,133.200625 136.950625,136.84375 132.4375,136.84375 Z" fill="#FFFFFF"></path>
      </g>
    </g>
  </Icon>
);

export default Truck;
