import React from 'react';
import Icon from 'components/icons/Icon';

const QuickShield = () => (
  <Icon width={20}
  height={20}
  title="Quick Shield Icon"
  description="Enterprise Employees eligible for Quick Shield" >  
  <path d="M9.08398 12.7708L13.834 8.02075L12.959 7.16658L9.14648 10.9791L7.04232 8.87492L6.12565 9.79158L9.08398 12.7708ZM10.0007 18.3124C8.05621 17.8263 6.45898 16.6978 5.20898 14.927C3.95898 13.1562 3.33398 11.2152 3.33398 9.10408V4.14575L10.0007 1.64575L16.6673 4.14575V9.10408C16.6673 11.2152 16.0423 13.1562 14.7923 14.927C13.5423 16.6978 11.9451 17.8263 10.0007 18.3124ZM10.0007 17.0208C11.5979 16.493 12.9 15.4964 13.9069 14.0312C14.9138 12.5659 15.4173 10.9235 15.4173 9.10408V5.02075L10.0007 2.97909L4.58398 5.02075V9.10408C4.58398 10.9235 5.08746 12.5659 6.0944 14.0312C7.10135 15.4964 8.40343 16.493 10.0007 17.0208Z" fill="#062ADB"/>
</Icon>
);

export default QuickShield;