import React from 'react';
import Icon from 'components/icons/Icon';

const RightArrowCircle = () => (
  <Icon 
  viewBox="0 0 24 24"
  width="24"
  height="24"
  fill="#f1fafe"
  stroke="currentColor"
 >
    <title>Right Arrow Circle</title>
    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#4765FA"/>
<path d="M12 16L16 12L12 8" stroke="#4765FA" />
<path d="M8 12H16" stroke="#4765FA" /> 
 </Icon>
);

export default RightArrowCircle;
